/**
 * --------------------------------------------------------------
 * Harapan nya modul ini digunakan global
 * artinya bisa digunakan untuk agent maupun custmer
 * SERVER_TIME : manipulate server time
 * LOG_DEMO : menampilkan log jika config mode == demo
 * --------------------------------------------------------------
 */
import rwConfig from "@/common/config/app.config.json";
export const SERVER_TIME = "ServerTime";
export const LOG_DEMO = "printDemo";

export default {
  state: {
    /**
     * --------------------------------------------------------------
     * state untuk listen waktu server menggunakan cron get dari firebase
     * time : time listen server others/server-time
     * diff : selisih time server dan local
     * isPlus : minus / plus (base on reload req api /config/customer)
     * countRefresh : berapa kali refresh init listen firebase if > 5 show swal
     * --------------------------------------------------------------
     */
    _server_time: {
      time: 0,
      diff: 0,
      isPlus: true,
      countRefresh: 0,
    },
  },
  getters: {
    getVuexGeneral(state) {
      return state;
    },
  },
  actions: {
    [SERVER_TIME]({ commit }, params) {
      commit(SERVER_TIME, params);
    },
    [LOG_DEMO](commit, message) {
      if (typeof rwConfig.mode !== "undefined" && rwConfig.mode === "demo") {
        if (typeof message !== "string") {
          console.warn(`[rw:info] : `, message);
        } else {
          console.warn(`[rw:info] : ${message}`);
        }
      }
    },
  },
  mutations: {
    [SERVER_TIME](state, params) {
      if (typeof params === "object") {
        let lama = state._server_time;
        state._server_time = Object.assign(lama, params);
      }
    },
  },
};
