import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";

/**
 * --------------------------------------------------------------
 * just for local purpose
 * @project firebase addin-firebasee
 * --------------------------------------------------------------
 */
let firebaseConfig = {
  apiKey: "AIzaSyDA4gtruhKu9bjJzldQAtfEd5Pd9oWhXus",
  authDomain: "rw-livechat.firebaseapp.com",
  databaseURL:
    "https://rw-livechat-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "rw-livechat",
  storageBucket: "rw-livechat.appspot.com",
  messagingSenderId: "329351702258",
  appId: "1:329351702258:web:fa4cab282ca01a4393526b",
  measurementId: "G-32HF9L10SZ",
};
firebase.initializeApp(firebaseConfig);

/**
 * --------------------------------------------------------------
 * init configures config
 * --------------------------------------------------------------
 */
const db = firebase.database();
const Auth = firebase.auth();

/**
 * --------------------------------------------------------------
 * export const
 * --------------------------------------------------------------
 */
export { db, Auth, firebase };
