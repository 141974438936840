import CryptoJS from "crypto-js";
import config from "@/common/config/app.config.json";
/**
 * --------------------------------------------------------------
 * for manipulate localStorage panel agent
 * --------------------------------------------------------------
 */
const secretKey = "_rumahweb1ndonesi4";

/**
 * --------------------------------------------------------------
 * for encrypt localStorage
 * --------------------------------------------------------------
 */
export const rwEncrypt = (data, isObject = true) => {
  data = isObject ? JSON.stringify(data) : data;
  return CryptoJS.AES.encrypt(data, secretKey).toString();
};

/**
 * --------------------------------------------------------------
 * for decrypt localStorage
 * --------------------------------------------------------------
 */
export const rwDecrypt = (data, isObject = true) => {
  let bytes = CryptoJS.AES.decrypt(data, secretKey);
  let result = bytes.toString(CryptoJS.enc.Utf8);
  if (isObject) {
    result = JSON.parse(result);
  }
  return result;
};

/**
 * --------------------------------------------------------------
 * for manipulate snooze
 * --------------------------------------------------------------
 */
const snoozeName = config.rwStorage.agent.snooze;
export const snooze = {
  methods: {
    mixAddSnooze: function (dataBaru) {
      let dataLama = localStorage.getItem(snoozeName);
      let snooze = { record: [], alert: [] };
      if (dataLama) {
        dataLama = rwDecrypt(dataLama);
        let dataFilter = dataLama.record.filter(
          (item) => item.threadId !== dataBaru.threadId
        );
        dataFilter.push(dataBaru);

        snooze.record = dataFilter;
        snooze.alert = dataLama.alert;
      } else {
        snooze.record = [dataBaru];
      }
      localStorage.setItem(snoozeName, rwEncrypt(snooze));
    },
    mixGetSnooze: function () {
      let data = localStorage.getItem(snoozeName);
      if (data) {
        return rwDecrypt(data);
      }
    },
    removeSnooze: function (threadId) {
      let data = localStorage.getItem(snoozeName);
      if (data) {
        data = rwDecrypt(data);
        let remove = data.record.filter((old) => old.threadId !== threadId);
        data.alert.push(threadId);
        let snooze = {
          record: remove,
          alert: data.alert,
        };
        localStorage.setItem(snoozeName, rwEncrypt(snooze));
      }
    },
    mixSnoozeRemoveAlert: function (threadid, removeSnooze = true) {
      // ============== jika balas tapi snooze belum habis ================
      if (removeSnooze) {
        this.removeSnooze(threadid);
      }

      // ============== remove alert ================
      let getSnooze = this.mixGetSnooze();
      if (typeof getSnooze !== "undefined") {
        let alerts = [];
        if (getSnooze.alert.includes(threadid)) {
          alerts = getSnooze.alert.filter((id) => id !== threadid);
        }
        let snooze = { record: getSnooze.record, alert: alerts };
        localStorage.setItem(snoozeName, rwEncrypt(snooze));
      }
    },
  },
};

/**
 * --------------------------------------------------------------
 * for manage localStorage config
 * --------------------------------------------------------------
 */
const configName = config.rwStorage.agent.config;
import ApiService from "@/common/API.service";
import ApiPath from "@/common/config/apiurl.config.json";

export const lsConfig = {
  methods: {
    /**
     * --------------------------------------------------------------
     * init config when reload page
     * --------------------------------------------------------------
     */
    mixConfigInit: function () {
      let lama = localStorage.getItem(configName);
      if (lama === null) {
        localStorage.setItem(configName, rwEncrypt(config));
      } else {
        try {
          let ls = localStorage.getItem(configName);
          rwDecrypt(ls);
        } catch (e) {
          // re-init config default
          localStorage.setItem(configName, rwEncrypt(config));
        }
      }
    },

    /**
     * --------------------------------------------------------------
     * request API and append config
     * --------------------------------------------------------------
     */
    mixConfigAppend: function () {
      return new Promise((resolve, reject) => {
        ApiService.get(ApiPath.config.index)
          .then(({ data }) => {
            // proses append config
            this.mixConfigInit();
            try {
              let configDefault = rwDecrypt(localStorage.getItem(configName));
              let configNew = Object.assign(configDefault, data.data);
              localStorage.setItem(configName, rwEncrypt(configNew));
              resolve(true);
            } catch (e) {
              reject(e.message);
            }
          })
          .catch((err) => {
            let message =
              typeof err.response !== "undefined"
                ? err.response.data.message
                : err.message;
            reject(message);
          });
      });
    },

    /**
     * --------------------------------------------------------------
     * untuk getter config by localStorage
     * --------------------------------------------------------------
     */
    mixConfigGetter: function () {
      try {
        let ls = localStorage.getItem(configName);
        return rwDecrypt(ls);
      } catch (e) {
        return false;
      }
    },
  },
};

/**
 * --------------------------------------------------------------
 * localStorage for manipulate unread message
 * --------------------------------------------------------------
 */
const unreadNameLS = config.rwStorage.agent.unread;
export const lsUnread = {
  methods: {
    mixUnreadPush: function (threadid) {
      let unreadJs = this.mixUnreadGet();
      if (unreadJs === null) {
        localStorage.setItem(
          unreadNameLS,
          rwEncrypt(JSON.stringify([threadid]))
        );
      } else {
        if (!unreadJs.includes(threadid)) {
          unreadJs.push(threadid);
          localStorage.setItem(
            unreadNameLS,
            rwEncrypt(JSON.stringify(unreadJs))
          );
        }
      }
    },
    mixUnreadRemove: function (threadid) {
      let unreadJs = this.mixUnreadGet();
      if (unreadJs !== null) {
        if (unreadJs.includes(threadid)) {
          let remove = unreadJs.filter((id) => id !== threadid);
          if (remove.length) {
            localStorage.setItem(
              unreadNameLS,
              rwEncrypt(JSON.stringify(remove))
            );
          } else {
            localStorage.removeItem(unreadNameLS);
          }
        }
      }
    },
    mixUnreadGet: function () {
      let UnreadLS = localStorage.getItem(unreadNameLS);
      if (UnreadLS !== null) {
        try {
          UnreadLS = JSON.parse(rwDecrypt(UnreadLS));
          return UnreadLS;
        } catch (e) {
          return null;
        }
      }
      return null;
    },
  },
};

/**
 * --------------------------------------------------
 * trash or logs
 * sendMsg, pilihDept
 * --------------------------------------------------
 */
const trashName = "rwtrash";
import { waktu } from "@/common/mixins/mix_helper";

export const trash = {
  methods: {
    trashAdd(title = "a", message = null, opt = false) {
      title = `${title}_${waktu.methods.mixEpoch()}`;
      let log = this.trashGet();
      let logBaru = {
        [title]: {
          msg: message,
          opt: opt ? opt : null,
        },
      };
      localStorage.setItem(
        trashName,
        rwEncrypt(JSON.stringify(Object.assign(log, logBaru)))
      );
    },
    trashGet() {
      let a = localStorage.getItem(trashName);
      if (a === null) {
        return {};
      }
      return JSON.parse(rwDecrypt(a));
    },
  },
};
