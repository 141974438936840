import Vue from "vue";
import Vuex from "vuex";

/**
 * --------------------------------------------------------------
 * import module store vuex
 * --------------------------------------------------------------
 */
import customer from "./customer.module";
import staff from "./staff.module";
import staffChat from "./staff.chat.module";
import general from "./general.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    customer,
    staff,
    staffChat,
    general,
  },
});
