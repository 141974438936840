/**
 * --------------------------------------------------------------
 * module store vuex for staff init 2021-04-03
 * --------------------------------------------------------------
 */
import { waktu, request } from "@/common/mixins/mix_helper";
import { staffCookies } from "@/common/mixins/mix_cookies";
import ApiService from "@/common/API.service";

/**
 * --------------------------------------------------------------
 * action type = tidak use mutation = request API / anything
 * --------------------------------------------------------------
 */
export const LOGIN = "staffLogin";
export const LOGOUT = "staffLogout";
export const SEND_MESSAGE = "staffSendMessage";
export const SEND_MESSAGE_FILE = "staffSendMessageFile";
export const CHANGE_STATUS = "staffChangeStatus";
export const SEND_LOG_API = "sendLogAPI";
export const BUBBLE_COLOR = "BubbleColor";

/**
 * --------------------------------------------------------------
 * for panel agent menu
 * --------------------------------------------------------------
 */
export const BREADCRUMB = "breadcrumb";
export const ACTIVE_LINK = "activeLink";

// mutation type
export const REMOVE_COOKIES = "removeCookies";

export default {
  state: {
    _breadcrumb: {
      title: "Home page",
      path: "",
    },
    _activeMenu: "/panel",
    _agentBgColor: [],
  },
  getters: {
    get_all(state) {
      return state;
    },
  },
  actions: {
    /**
     * --------------------------------------------------------------
     * @param state
     * @param payload : Object {email, password, deptid}
     * --------------------------------------------------------------
     */
    [LOGIN](state, payload) {
      return new Promise((resolve, reject) => {
        let http = request.data();
        ApiService.post(http.URL.auth.staff, payload)
          .then(({ data }) => {
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    /**
     * --------------------------------------------------------------
     * logout just remove cookies
     * --------------------------------------------------------------
     */
    [LOGOUT](state) {
      state.commit(REMOVE_COOKIES);
    },

    /**
     * --------------------------------------------------------------
     * @param state
     * @param params : Object {tipe, message}
     * --------------------------------------------------------------
     */
    [SEND_MESSAGE](state, params) {
      return new Promise((resolve, reject) => {
        try {
          if (typeof params.message === "object") {
            let http = request.data();
            http.API.post(http.URL.message.send, params.message)
              .then((data) => {
                resolve(data);
              })
              .catch(({ response }) => {
                reject(response);
              });
          } else {
            reject("Params message must be an object");
          }
        } catch (error) {
          reject(error);
        }
      });
    },

    /**
     * --------------------------------------------------------------
     * upload file ex. gambar
     * @param state
     * @param params : Object
     * --------------------------------------------------------------
     */
    [SEND_MESSAGE_FILE](state, params) {
      return new Promise((resolve, reject) => {
        let Cookies = staffCookies.data().cookies.get();
        if (
          typeof Cookies !== "undefined" &&
          Cookies.isLogin &&
          Cookies.staff.id
        ) {
          let uuid = () => {
            let str = Math.random().toString(36).substring(2);
            let epoch = Math.round(Date.now() / 1000);
            return str + "." + epoch;
          };
          let message = {
            id: Cookies.staff.id,
            time: waktu.methods.mixEpoch(),
            name: Cookies.staff.name,
            tipe: typeof params.tipe !== "undefined" ? params.tipe : "file",
            actor: "staff",
            threadid: Cookies.threadOpen.id,
            firebaseid: Cookies.threadOpen.fbid,
            is_reply: true,
            uuid: uuid(),
          };

          let formData = new FormData();
          let http = request.data();
          formData.append("file", params.message);
          formData.append("data", JSON.stringify(message));

          http.API.postFile(http.URL.message.sendFile, formData)
            .then((data) => {
              // refMessages.push(message);
              resolve(data);
            })
            .catch(({ response }) => {
              reject(response.data);
            });
        } else {
          reject({ message: "Cookies not found!" });
        }
      });
    },

    /**
     * --------------------------------------------------------------
     * mengubah status ke on|off|away
     * @param state
     * @param params: {status: "on"}
     * --------------------------------------------------------------
     */
    [CHANGE_STATUS](state, params) {
      return new Promise((resolve, reject) => {
        let status = ["on", "off", "away"];
        if (status.includes(params.status)) {
          let staff = staffCookies.data().cookies.get("staff");
          if (typeof staff.id !== "undefined") {
            let post = {
              id: staff.id,
              status: params.status,
            };
            let http = request.data();
            http.API.post(http.URL.agent.changeStatus, post)
              .then(({ data }) => {
                resolve(data);
              })
              .catch(({ response }) => {
                reject(response);
              });
          } else {
            reject({ message: "Cookies staff.id not found" });
          }
        } else {
          reject({ message: `status ${params.status} not allowed` });
        }
      });
    },

    [BREADCRUMB](state, params) {
      state.commit(BREADCRUMB, params);
    },

    [ACTIVE_LINK](state, params) {
      state.commit(ACTIVE_LINK, params);
    },

    /**
     * --------------------------------------------------------------
     * send log to API
     * --------------------------------------------------------------
     */
    [SEND_LOG_API](state, params) {
      // --- inject data
      let nav = window.navigator;
      params["userAgent"] = nav.userAgent;
      params["platform"] = nav.platform;
      params["appVersion"] = nav.appVersion;
      let http = request.data();
      return new Promise((resolve, reject) => {
        http.API.post(http.URL.message.log, params)
          .then((data) => {
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    /**
     * --------------------------------------------------------------
     * init 2021-090-24
     * request warna color per agent (base on color departmetn)
     * --------------------------------------------------------------
     */
    [BUBBLE_COLOR]({ commit }) {
      let http = request.data();
      return new Promise((resolve, reject) => {
        http.API.get(http.URL.agent.bgColor)
          .then(({ data }) => {
            commit(BUBBLE_COLOR, data);
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
  mutations: {
    [REMOVE_COOKIES]() {
      staffCookies.data().cookies.remove();
    },
    [BREADCRUMB](state, params) {
      state._breadcrumb.title = params.title;
      state._breadcrumb.path = params.path;
    },
    [ACTIVE_LINK](state, params) {
      state._activeMenu = params;
    },
    [BUBBLE_COLOR](state, bgColors) {
      state._agentBgColor = bgColors;
    },
  },
};
