/**
 * --------------------------------------------------------------
 * for API service library
 * init 2021-04-01
 * --------------------------------------------------------------
 */
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import { API_URL } from "@/common/config";

const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = API_URL;
    // Vue.axios.defaults.withCredentials = true;
  },
  /**
   * ------------------------------------------------------------
   * Set the default HTTP request headers
   * ------------------------------------------------------------
   */
  setHeader() {
    let token = localStorage.getItem("rw:token");
    Vue.axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  },

  /**
   * --------------------------------------------------------------
   * GET request with axios
   * @param endpoint : String
   * @param slug : Boolean :: bisa ? (get) atau /(slash)
   * @param config : Object for axios configuration
   * --------------------------------------------------------------
   */
  get(endpoint, slug = false, config = {}) {
    if (!slug) {
      return Vue.axios.get(`${endpoint}`, config);
    } else {
      // jika slug isinya get (?)
      if (slug.indexOf("?") > -1 || slug.indexOf("&") > -1) {
        return Vue.axios.get(`${endpoint}${slug}`, config);
      } else {
        return Vue.axios.get(`${endpoint}/${slug}`, config);
      }
    }
  },
  /**
   * --------------------------------------------------------------
   * request post
   * @param endpoint : String
   * @param body : Object
   * --------------------------------------------------------------
   */
  post(endpoint, body) {
    return Vue.axios.post(`${endpoint}`, body);
  },

  /**
   * --------------------------------------------------------------
   * for upload file ex gambar
   * @param endpoint : String
   * @param file : object (bisa gabung dg biner, formData)
   * @ref : https://www.itsolutionstuff.com/post/file-upload-using-vue-js-axios-phpexample.html
   * --------------------------------------------------------------
   */
  postFile(endpoint, file) {
    return Vue.axios.post(`${endpoint}`, file, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
};

export default ApiService;
